import { Loader } from '@googlemaps/js-api-loader';
import $ from "jquery"

const googleMapsLoader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places"]
});

document.addEventListener("turbolinks:load", function() {
  var markers = document.getElementsByClassName("marker-tag")
  var not_visible_markers = document.getElementsByClassName("not-visible-marker-tag")

  if($("#categorySideMap").length > 0){
    display_map_with_markers("categorySideMap", markers, not_visible_markers);
  }
  if($("#mapOnTopStorageShowPage").length > 0){
    display_map_with_markers("mapOnTopStorageShowPage", markers, not_visible_markers)
  }
})

var center;
var marker;
var marker_position;
function display_map_with_markers(map_container_id, markers, not_visible_markers, zoom = 17){
  if(markers.length + not_visible_markers.length == 0){
    display_empty_map_from_germany(map_container_id)
  } else {
    googleMapsLoader.load().then(() => {
      center = markers[0] || not_visible_markers[0]
      var map = new google.maps.Map(document.getElementById(map_container_id), {
        zoom: zoom,
        center: parsePositionFromMarkerTag(center),
        disableDefaultUI: true
      });

      var lat_lng_bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < markers.length; i++) {
        marker = add_marker(google, map, markers[i]);
        lat_lng_bounds.extend(marker.getPosition())
      }
      for (var i = 0; i < not_visible_markers.length; i++) {
        marker_position = parsePositionFromMarkerTag(not_visible_markers[i])
        lat_lng_bounds.extend(marker_position)
      }
      map.fitBounds(lat_lng_bounds)
      var zoomChangeBoundsListener = google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
        if (this.getZoom() > 14){
          this.setZoom(14);
        }
      });
      setTimeout(function(){google.maps.event.removeListener(zoomChangeBoundsListener)}, 2000);
    });
  }
}

function display_empty_map_from_germany(map_container_id){
  googleMapsLoader.load().then(() => {
    var map = new google.maps.Map(document.getElementById(map_container_id), {
      zoom: 6,
      center: {lat: 50.84, lng: 10.21}
    })
  });
}

function parsePositionFromMarkerTag(marker_tag){
  var lng = parseFloat(marker_tag.getElementsByClassName("lng")[0].innerText)
  var lat = parseFloat(marker_tag.getElementsByClassName("lat")[0].innerText)
  return {lng: lng, lat: lat}
}

function add_marker(google, map, marker_tag){
  var title = marker_tag.getElementsByClassName("title")[0].innerText
  var position = parsePositionFromMarkerTag(marker_tag)
  var icon_color = marker_tag.classList.contains("green") ? "green" : "red"
  var icon = "http://maps.google.com/mapfiles/ms/icons/" + icon_color + "-dot.png"
  var url = parseUrlFromMarkerTag(marker_tag)

  marker = new google.maps.Marker({
    position: position,
    map: map,
    title: title,
    icon: icon,
    url: url
  });
  google.maps.event.addListener(marker, 'click', function() {
      window.location.href = this.url;
  });
  return marker;
}

function parseUrlFromMarkerTag(marker_tag) {
  return marker_tag.getElementsByClassName("url")[0].innerText
}
